:root {
  --rs-gray-50: #f9fafb;
  --rs-gray-100: #f2f4f7;
  --rs-gray-200: #eaecf0;
  --rs-gray-300: #d0d5dd;
  --rs-gray-400: #98a2b3;
  --rs-gray-500: #667085;
  --rs-gray-600: #475467;
  --rs-gray-700: #344054;
  --rs-gray-800: #1d2939;
  --rs-gray-900: #101828;
  --rs-customGray-800: #353647;
  --rs-black: #090c11;

  .rs-theme-light {
    --rs-primary-50: #ecedfe;
    --rs-primary-100: #d8dbfd;
    --rs-primary-200: #b1b7fb;
    --rs-primary-300: #868ff9;
    --rs-primary-400: #5f6bf7;
    --rs-primary-500: #3747f5;
    --rs-primary-600: #0b1de4;
    --rs-primary-700: #0816aa;
    --rs-primary-800: #060f75;
    --rs-primary-900: #03083a;
    --rs-red-50: #fff2f2;
    --rs-red-100: #fccfcf;
    --rs-red-200: #faa9a7;
    --rs-red-300: #fa8682;
    --rs-red-400: #f7635c;
    --rs-red-500: #f44336;
    --rs-red-600: #eb3626;
    --rs-red-700: #d62915;
    --rs-red-800: #b81c07;
    --rs-red-900: #8f1300;
    --rs-orange-50: #fff8f2;
    --rs-orange-100: #ffdfc2;
    --rs-orange-200: #fcc690;
    --rs-orange-300: #fcb160;
    --rs-orange-400: #fa9b2f;
    --rs-orange-500: #fa8900;
    --rs-orange-600: #f08800;
    --rs-orange-700: #db8000;
    --rs-orange-800: #bd7100;
    --rs-orange-900: #945b00;
    --rs-yellow-50: #fffaf2;
    --rs-yellow-100: #ffe9c2;
    --rs-yellow-200: #ffd991;
    --rs-yellow-300: #ffca61;
    --rs-yellow-400: #ffbe30;
    --rs-yellow-500: #ffb300;
    --rs-yellow-600: #f5af00;
    --rs-yellow-700: #e0a500;
    --rs-yellow-800: #c29100;
    --rs-yellow-900: #997500;
    --rs-green-50: #eeffed;
    --rs-green-100: #c8f0c7;
    --rs-green-200: #a5e0a4;
    --rs-green-300: #82cf82;
    --rs-green-400: #65bf67;
    --rs-green-500: #4caf50;
    --rs-green-600: #37ab3c;
    --rs-green-700: #22a12a;
    --rs-green-800: #0f9119;
    --rs-green-900: #007d0c;
    --rs-customGreen-50: hsl(153, 96%, 93%);
    --rs-customGreen-100: hsl(153, 96%, 86%);
    --rs-customGreen-200: hsl(153, 96%, 79%);
    --rs-customGreen-300: hsl(153, 96%, 72%);
    --rs-customGreen-400: hsl(153, 96%, 65%);
    --rs-customGreen-500: hsl(153, 96%, 58%);
    --rs-customGreen-600: hsl(153, 96%, 51%);
    --rs-customGreen-700: hsl(153, 96%, 44%);
    --rs-customGreen-800: hsl(153, 96%, 37%);
    /* background: #039855 */
    --rs-customGreen-900: hsl(153, 96%, 30%);
    /* background: #039855 */
    --customBgActiveButton: hsl(153, 96%, 30%);
    /* background: #12B76A */
    --customBgActiveIcon: hsl(152, 82%, 39%);
    /* background: #FFF */
    --customBgInactiveButton: hsl(0, 0%, 100%);
    /* background: #E7E5FF */
    --customBgInactiveIcon: hsl(245, 100%, 95%);
    --customActiveIconColor: hsl(0, 0%, 100%);
    --customInactiveIconColor: #3f33ff;

    /* Custom background Dashboard Light */
    /* background: #ffffff hsl(0, 0%, 100%) */
    --customBgDashboard: hsl(0, 0%, 100%);
    /* #D0D5DD hsl(217, 16%, 84%)*/
    --customBgNavbar: hsl(217, 16%, 84%);

    --rs-cyan-100: #bcf4f7;
    --rs-cyan-200: #87e6ed;
    --rs-cyan-300: #57dae6;
    --rs-cyan-400: #2acadb;
    --rs-cyan-500: #00bcd4;
    --rs-cyan-600: #00b1cc;
    --rs-cyan-700: #00a0bd;
    --rs-cyan-800: #008aa6;
    --rs-cyan-900: #006e87;
    --rs-blue-50: #f0f9ff;
    --rs-blue-100: #c5e7fc;
    --rs-blue-200: #9bd4fa;
    --rs-blue-300: #72c0f7;
    --rs-blue-400: #49abf5;
    --rs-blue-500: var(--rs-primary-400);
    --rs-blue-600: #1787e8;
    --rs-blue-700: #0d73d4;
    --rs-blue-800: #045cb5;
    --rs-blue-900: #00448c;
    --rs-violet-50: #f6f2ff;
    --rs-violet-100: #d5c9f0;
    --rs-violet-200: #b6a1e3;
    --rs-violet-300: #987bd4;
    --rs-violet-400: #805ac7;
    --rs-violet-500: #673ab7;
    --rs-violet-600: #5f2bb3;
    --rs-violet-700: #531ba8;
    --rs-violet-800: #470c99;
    --rs-violet-900: #390085;
    --rs-state-success: #4caf50;
    --rs-state-info: var(--rs-primary-400);
    --rs-state-warning: #ffb300;
    --rs-state-error: #f44336;
    --rs-body: #fff;
    --rs-bg-success: #edfae1;
    --rs-bg-info: #e9f5fe;
    --rs-bg-warning: #fff9e6;
    --rs-bg-error: #fde9ef;
    --rs-text-link: var(--rs-primary-500);
    --rs-text-link-hover: #0a5dc2;
    --rs-text-link-active: #004299;
    --rs-text-primary: #575757;
    --rs-text-secondary: #8e8e93;
    --rs-text-tertiary: #a6a6a6;
    --rs-text-heading: #272c36;
    --rs-text-inverse: #f7f7fa;
    --rs-text-heading-inverse: #fff;
    --rs-text-active: var(--rs-primary-500);
    --rs-text-disabled: #575757;
    --rs-text-error: #f44336;
    --rs-border-primary: #e5e5ea;
    --rs-border-secondary: #f2f2f5;
    --rs-bg-card: #fff;
    --rs-bg-overlay: #f7f7fa;
    --rs-bg-well: #f7f7fa;
    --rs-bg-active: #3747f5;
    --rs-bg-backdrop: rgba(39, 44, 54, 0.3);
    --rs-state-hover-bg: #f2faff;
    --rs-state-focus-shadow: transparent;
    --rs-state-focus-outline: 3px solid rgba(52, 152, 255, 0.25);
    --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12),
      0 0 10px rgba(0, 0, 0, 0.06);
    --rs-btn-default-bg: #f7f7fa;
    --rs-btn-default-text: #575757;
    --rs-btn-default-hover-bg: #e5e5ea;
    --rs-btn-default-active-bg: #d9d9d9;
    --rs-btn-default-active-text: #272c36;
    --rs-btn-default-disabled-bg: #cdcff0;
    --rs-btn-default-disabled-text: #fff;
    --rs-btn-primary-bg: #3747f5;
    --rs-btn-primary-text: #fff;
    --rs-btn-primary-hover-bg: #2589f5;
    --rs-btn-primary-active-bg: var(--rs-primary-500);
    --rs-btn-subtle-text: #8e8e93;
    --rs-btn-subtle-hover-bg: #f7f7fa;
    --rs-btn-subtle-hover-text: #575757;
    --rs-btn-subtle-active-bg: #e5e5ea;
    --rs-btn-subtle-active-text: #272c36;
    --rs-btn-subtle-disabled-text: #c5c6c7;
    --rs-btn-ghost-border: var(--rs-primary-500);
    --rs-btn-ghost-text: var(--rs-primary-500);
    --rs-btn-ghost-hover-border: #0a5dc2;
    --rs-btn-ghost-hover-text: #0a5dc2;
    --rs-btn-ghost-active-border: #004299;
    --rs-btn-ghost-active-text: #004299;
    --rs-btn-link-text: var(--rs-primary-500);
    --rs-btn-link-hover-text: #0a5dc2;
    --rs-btn-link-active-text: #004299;
    --rs-iconbtn-addon: #f2f2f5;
    --rs-iconbtn-activated-addon: #d9d9d9;
    --rs-iconbtn-pressed-addon: #c5c6c7;
    --rs-iconbtn-primary-addon: #2589f5;
    --rs-iconbtn-primary-activated-addon: var(--rs-primary-500);
    --rs-iconbtn-primary-pressed-addon: #0a5dc2;
    --rs-divider-border: #e5e5ea;
    --rs-loader-ring: rgba(247, 247, 250, 0.8);
    --rs-loader-rotor: #a6a6a6;
    --rs-loader-backdrop: rgba(255, 255, 255, 0.9);
    --rs-loader-ring-inverse: rgba(247, 247, 250, 0.3);
    --rs-loader-rotor-inverse: #fff;
    --rs-loader-backdrop-inverse: rgba(39, 44, 54, 0.83);
    --rs-message-success-header: var(--rs-text-heading);
    --rs-message-success-text: var(--rs-text-primary);
    --rs-message-success-icon: #4caf50;
    --rs-message-success-bg: #eeffed;
    --rs-message-info-header: var(--rs-text-heading);
    --rs-message-info-text: var(--rs-text-primary);
    --rs-message-info-icon: var(--rs-primary-400);
    --rs-message-info-bg: #f0f9ff;
    --rs-message-warning-header: var(--rs-text-heading);
    --rs-message-warning-text: var(--rs-text-primary);
    --rs-message-warning-icon: #ffb300;
    --rs-message-warning-bg: #fffaf2;
    --rs-message-error-header: var(--rs-text-heading);
    --rs-message-error-text: var(--rs-text-primary);
    --rs-message-error-icon: #f44336;
    --rs-message-error-bg: #fff2f2;
    --rs-tooltip-text: rgba(39, 44, 54, 0.83);
    --rs-progress-bg: #e5e5ea;
    --rs-progress-bar: #3747f5;
    --rs-progress-bar-success: #4caf50;
    --rs-progress-bar-fail: #f44336;
    --rs-placeholder: #f2f2f5;
    --rs-placeholder-active: #e5e5ea;
    --rs-dropdown-divider: #e5e5ea;
    --rs-dropdown-item-bg-hover: #f2faff;
    --rs-dropdown-item-bg-active: rgba(242, 250, 255, 0.5);
    --rs-dropdown-item-text-active: var(--rs-primary-500);
    --rs-dropdown-header-text: #a6a6a6;
    --rs-dropdown-shadow: 0 0 10px rgba(0, 0, 0, 0.06),
      0 4px 4px rgba(0, 0, 0, 0.12);
    --rs-menuitem-active-bg: rgba(204, 233, 255, 0.5);
    --rs-menuitem-active-text: var(--rs-primary-500);
    --rs-steps-border: #8e8e93;
    --rs-steps-state-finish: #3747f5;
    --rs-steps-border-state-finish: #3747f5;
    --rs-steps-state-wait: #8e8e93;
    --rs-steps-state-process: #3747f5;
    --rs-steps-state-error: #f44336;
    --rs-steps-border-state-error: #f44336;
    --rs-steps-icon-state-process: #3747f5;
    --rs-steps-icon-state-error: #f44336;
    --rs-navs-text: #a4a9b3;
    --rs-navs-text-hover: #fff;
    --rs-navs-bg-hover: var(--rs-primary-400);
    --rs-navs-text-active: #fff;
    --rs-navs-bg-active: var(--rs-primary-400);
    --rs-navs-tab-border: var(--rs-primary-400);
    --rs-navs-subtle-border: var(--rs-primary-400);
    --rs-navs-selected: #fff;
    --rs-navbar-default-bg: #f7f7fa;
    --rs-navbar-default-text: #575757;
    --rs-navbar-default-selected-text: #de3d68;
    --rs-navbar-default-hover-bg: #e5e5ea;
    --rs-navbar-default-hover-text: #575757;
    --rs-navbar-inverse-bg: #3747f5;
    --rs-navbar-inverse-text: #fff;
    --rs-toggle-checked-bg: var(--rs-primary-400);
    --rs-navbar-inverse-selected-bg: var(--rs-primary-500);
    --rs-navbar-inverse-hover-bg: #2589f5;
    --rs-navbar-subtle-bg: #fff;
    --rs-navbar-subtle-text: #8e8e93;
    --rs-navbar-subtle-selected-text: var(--rs-primary-500);
    --rs-navbar-subtle-hover-bg: #f7f7fa;
    --rs-navbar-subtle-hover-text: #575757;
    --rs-input-bg: #fff;
    --rs-input-focus-border: #3747f5;
    --rs-input-disabled-bg: #f7f7fa;
    --rs-listbox-option-hover-bg: rgba(204, 233, 255, 0.5);
    --rs-listbox-option-hover-text: var(--rs-primary-500);
    --rs-listbox-option-selected-text: var(--rs-primary-500);
    --rs-listbox-option-selected-bg: #f2faff;
    --rs-listbox-option-disabled-text: #c5c6c7;
    --rs-listbox-option-disabled-selected-text: #a6d7ff;
    --rs-checkbox-icon: #fff;
    --rs-checkbox-border: #d9d9d9;
    --rs-checkbox-checked-bg: #3747f5;
    --rs-checkbox-disabled-bg: #f7f7fa;
    --rs-radio-marker: #fff;
    --rs-radio-border: #d9d9d9;
    --rs-radio-checked-bg: #3747f5;
    --rs-radio-disabled-bg: #f7f7fa;
    --rs-toggle-bg: #d9d9d9;
    --rs-toggle-thumb: #fff;
    --rs-toggle-hover-bg: #c5c6c7;
    --rs-toggle-disabled-bg: #f7f7fa;
    --rs-toggle-disabled-thumb: #fff;
    --rs-toggle-checked-hover-bg: #2589f5;
    --rs-toggle-checked-disabled-bg: #cce9ff;
    --rs-toggle-checked-disabled-thumb: #fff;
    --rs-slider-bar: #f2f2f5;
    --rs-slider-hover-bar: #e5e5ea;
    --rs-slider-thumb-border: #3747f5;
    --rs-slider-thumb-bg: #fff;
    --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
    --rs-slider-progress: #3747f5;
    --rs-uploader-item-bg: #d9d9d9;
    --rs-uploader-item-hover-bg: #f7f7fa;
    --rs-uploader-overlay-bg: rgba(255, 255, 255, 0.8);
    --rs-uploader-dnd-bg: #fff;
    --rs-uploader-dnd-border: #e5e5ea;
    --rs-uploader-dnd-hover-border: #3747f5;
    --rs-avatar-bg: #d9d9d9;
    --rs-avatar-text: #fff;
    --rs-badge-bg: #f44336;
    --rs-badge-text: #fff;
    --rs-tag-bg: #f7f7fa;
    --rs-tag-close: #f44336;
    --rs-carousel-bg: #8e8e93;
    --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
    --rs-carousel-indicator-hover: #fff;
    --rs-carousel-indicator-active: #3747f5;
    --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
      0 0 10px rgba(0, 0, 0, 0.06);
    --rs-list-bg: #fff;
    --rs-list-border: #e5e5ea;
    --rs-list-hover-bg: #f2faff;
    --rs-list-placeholder-bg: rgba(242, 250, 255, 0.5);
    --rs-list-placeholder-border: #3747f5;
    --rs-timeline-indicator-bg: #d9d9d9;
    --rs-timeline-indicator-active-bg: #3747f5;
    --rs-table-shadow: rgba(9, 9, 9, 0.08);
    --rs-table-sort: #3747f5;
    --rs-table-resize: #3747f5;
    --rs-table-scrollbar-track: #e5e5ea;
    --rs-table-scrollbar-thumb: #575757;
    --rs-table-scrollbar-thumb-active: #272c36;
    --rs-table-scrollbar-vertical-track: rgba(229, 229, 234, 0.4);
    --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
      0 0 10px rgba(0, 0, 0, 0.06);
    --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
      0 0 10px rgba(0, 0, 0, 0.06);
    --rs-form-errormessage-text: #f44336;
    --rs-form-errormessage-bg: #fff;
    --rs-form-errormessage-border: #e5e5ea;
    --rs-picker-value: var(--rs-primary-500);
    --rs-picker-count: #3747f5;
    --rs-calendar-today-bg: #3747f5;
    --rs-calendar-today-text: #fff;
    --rs-calendar-range-bg: rgba(204, 233, 255, 0.5);
    --rs-calendar-time-unit-bg: #f7f7fa;
    --rs-popover-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    --rs-tooltip-bg: #e5e5ea;

    .logo-light {
      display: block;
    }

    .logo-dark {
      display: none;
    }

    .rs-tooltip-notification {
      padding: 10px;
      background-color: #fff;
      border: 1px solid #e5e5ea;
      max-width: 400px !important;

      @media screen and (max-width: 768px) {
        max-width: 320px !important;
        width: 100% !important;
      }
    }

    .rs-tooltip.placement-bottom-end:after {
      display: none !important;
    }

    .multi-email {
      input {
        flex: 1;
        background: transparent;
      }

      span {
        color: #8e8e93;
      }
    }

    .portal-content-light {
      h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      h3 {
        display: block;
        font-size: 1.17em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      h4 {
        display: block;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      h5 {
        display: block;
        font-size: 0.83em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      h6 {
        display: block;
        font-size: 0.67em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      pre {
        display: block;
        font-family: monospace;
        white-space: pre;
        margin: 1em 0px;
      }
      ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
      }
      li {
        display: list-item;
        text-align: -webkit-match-parent;
      }
      ol {
        list-style-type: decimal;
      }
    }
  }

  .rs-theme-dark {
    --rs-primary-50: #01041d;
    --rs-primary-100: #03083a;
    --rs-primary-200: #060f75;
    --rs-primary-300: #0816aa;
    --rs-primary-400: #0b1de4;
    --rs-primary-500: #3747f5;
    --rs-primary-600: #5f6bf7;
    --rs-primary-700: #868ff9;
    --rs-primary-800: #b1b7fb;
    --rs-primary-900: #d8dbfd;
    --rs-red-50: #ffeded;
    --rs-red-100: #fccaca;
    --rs-red-200: #faa9a7;
    --rs-red-300: #f58884;
    --rs-red-400: #f26a63;
    --rs-red-500: #f04f43;
    --rs-red-600: #e63f30;
    --rs-red-700: #d12f1d;
    --rs-red-800: #b3200c;
    --rs-red-900: #8a1200;
    --rs-orange-50: #fff9f2;
    --rs-orange-100: #ffe2c2;
    --rs-orange-200: #ffce91;
    --rs-orange-300: #ffba61;
    --rs-orange-400: #ffa930;
    --rs-orange-500: #ff9800;
    --rs-orange-600: #f59700;
    --rs-orange-700: #e08e00;
    --rs-orange-800: #c27e00;
    --rs-orange-900: #996600;
    --rs-yellow-50: #fff9f0;
    --rs-yellow-100: #ffedd1;
    --rs-yellow-200: #ffe2b3;
    --rs-yellow-300: #ffd894;
    --rs-yellow-400: #ffcf75;
    --rs-yellow-500: #ffc757;
    --rs-yellow-600: #f5bb3d;
    --rs-yellow-700: #e0a824;
    --rs-yellow-800: #c28f0e;
    --rs-yellow-900: #997000;
    --rs-green-50: #f3fff2;
    --rs-green-100: #cff0ce;
    --rs-green-200: #aee0ad;
    --rs-green-300: #8ccf8c;
    --rs-green-400: #71bf72;
    --rs-green-500: #58b15b;
    --rs-green-600: #3fab45;
    --rs-green-700: #27a12f;
    --rs-green-800: #10911b;
    --rs-green-900: #007d0c;
    --rs-customGreen-50: hsl(153, 96%, 93%);
    --rs-customGreen-100: hsl(153, 96%, 86%);
    --rs-customGreen-200: hsl(153, 96%, 79%);
    --rs-customGreen-300: hsl(153, 96%, 72%);
    --rs-customGreen-400: hsl(153, 96%, 65%);
    --rs-customGreen-500: hsl(153, 96%, 58%);
    --rs-customGreen-600: hsl(153, 96%, 51%);
    --rs-customGreen-700: hsl(153, 96%, 44%);
    --rs-customGreen-800: hsl(153, 96%, 37%);
    /* background: #12B76A */
    --rs-customGreen-850: hsl(152, 82%, 39%);
    /* background: #039855 */
    --rs-customGreen-900: hsl(153, 96%, 30%);
    /* background: #039855 */
    --customBgActiveButton: hsl(153, 96%, 30%);
    /* background: #1A1B23 */
    --customBgInactiveButton: hsl(233, 15%, 12%);
    /* background: #12B76A */
    --customBgActiveIcon: hsl(152, 82%, 39%);
    /* background: #343546 */
    --customBgInactiveIcon: hsl(237, 15%, 24%);

    /* Custom background Dashboard Dark */
    /* background: #090C11 hsl(218, 31%, 5%) */
    --customBgDashboard: hsl(218, 31%, 5%);
    /* #010409 hsl(218, 80%, 2%); */
    --customBgNavbar: hsl(218, 80%, 2%);

    --rs-cyan-50: #f2ffff;
    --rs-cyan-100: #bcf4f7;
    --rs-cyan-200: #87e6ed;
    --rs-cyan-300: #57dae6;
    --rs-cyan-400: #2acadb;
    --rs-cyan-500: #00bcd4;
    --rs-cyan-600: #00b1cc;
    --rs-cyan-700: #00a0bd;
    --rs-cyan-800: #008aa6;
    --rs-cyan-900: #006e87;
    --rs-blue-50: #edf9ff;
    --rs-blue-100: #c0e8fc;
    --rs-blue-200: #93d6fa;
    --rs-blue-300: #67c1f5;
    --rs-blue-400: #3dadf2;
    --rs-blue-500: #1499ef;
    --rs-blue-600: #0e8ce6;
    --rs-blue-700: #087ad1;
    --rs-blue-800: #0464b3;
    --rs-blue-900: #00498a;
    --rs-violet-50: #f6f2ff;
    --rs-violet-100: #d5c9f0;
    --rs-violet-200: #b6a1e3;
    --rs-violet-300: #987bd4;
    --rs-violet-400: #805ac7;
    --rs-violet-500: #673ab7;
    --rs-violet-600: #5f2bb3;
    --rs-violet-700: #531ba8;
    --rs-violet-800: #470c99;
    --rs-violet-900: #390085;
    --rs-state-success: #4caf50;
    --rs-state-info: var(--rs-primary-400);
    --rs-state-warning: #ffb300;
    --rs-state-error: #f44336;
    --rs-body: #0f131a;
    --rs-text-link: var(--rs-primary-400);
    --rs-text-link-hover: #59d0ff;
    --rs-text-link-active: #80ddff;
    --rs-text-primary: #e9ebf0;
    --rs-text-secondary: #a4a9b3;
    --rs-text-tertiary: #858b94;
    --rs-text-heading: #fff;
    --rs-text-inverse: #1a1d24;
    --rs-text-heading-inverse: #0f131a;
    --rs-text-active: var(--rs-primary-400);
    --rs-text-disabled: #a4a9b3;
    --rs-border-primary: #3c3f43;
    --rs-border-secondary: #292d33;
    --rs-bg-card: var(--rs-gray-900);
    --rs-bg-overlay: #0a0e14;
    --rs-bg-well: #0f131a;
    --rs-bg-active: var(--rs-primary-400);
    --rs-bg-backdrop: rgba(15, 19, 26, 0.8);
    --rs-state-hover-bg: #3c3f43;
    --rs-state-focus-shadow: transparent;
    --rs-state-focus-outline: 3px solid rgba(52, 195, 255, 0.25);
    --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12),
      0 0 10px rgba(0, 0, 0, 0.06);
    --rs-btn-default-bg: var(--rs-gray-800);
    --rs-btn-default-text: #e9ebf0;
    --rs-btn-default-hover-bg: #5c6066;
    --rs-btn-default-active-bg: #858b94;
    --rs-btn-default-active-text: #fff;
    --rs-btn-default-disabled-bg: #3c3f43;
    --rs-btn-default-disabled-text: #e9ebf0;
    --rs-btn-primary-bg: var(--rs-primary-400);
    --rs-btn-primary-text: #fff;
    --rs-btn-primary-hover-bg: var(--rs-primary-400);
    --rs-btn-primary-active-bg: var(--rs-primary-400);
    --rs-btn-subtle-text: #a4a9b3;
    --rs-btn-subtle-hover-bg: #292d33;
    --rs-btn-subtle-hover-text: #e9ebf0;
    --rs-btn-subtle-active-bg: #6a6f76;
    --rs-btn-subtle-active-text: #fff;
    --rs-btn-subtle-disabled-text: #5c6066;
    --rs-btn-ghost-border: var(--rs-primary-400);
    --rs-btn-ghost-text: var(--rs-primary-400);
    --rs-toggle-checked-bg: var(--rs-primary-400);
    --rs-btn-ghost-hover-border: #59d0ff;
    --rs-btn-ghost-hover-text: #59d0ff;
    --rs-btn-ghost-active-border: #a6e9ff;
    --rs-btn-ghost-active-text: #a6e9ff;
    --rs-btn-link-text: var(--rs-primary-400);
    --rs-btn-link-hover-text: #59d0ff;
    --rs-btn-link-active-text: #a6e9ff;
    --rs-iconbtn-addon: #5c6066;
    --rs-iconbtn-activated-addon: #6a6f76;
    --rs-iconbtn-pressed-addon: #a4a9b3;
    --rs-iconbtn-primary-addon: #25b3f5;
    --rs-iconbtn-primary-activated-addon: var(--rs-primary-400);
    --rs-iconbtn-primary-pressed-addon: #59d0ff;
    --rs-divider-border: #3c3f43;
    --rs-loader-ring: rgba(233, 235, 240, 0.3);
    --rs-loader-rotor: #fff;
    --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
    --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
    --rs-loader-rotor-inverse: #5c6066;
    --rs-loader-backdrop-inverse: rgba(255, 255, 255, 0.9);
    --rs-message-success-header: #fff;
    --rs-message-success-text: #fff;
    --rs-message-success-icon: #fff;
    --rs-message-success-bg: #4caf50;
    --rs-message-info-header: #fff;
    --rs-message-info-text: #fff;
    --rs-message-info-icon: #fff;
    --rs-message-info-bg: var(--rs-primary-400);
    --rs-message-warning-header: #0f131a;
    --rs-message-warning-text: #0f131a;
    --rs-message-warning-icon: #0f131a;
    --rs-message-warning-bg: #ffb300;
    --rs-message-error-header: #fff;
    --rs-message-error-text: #fff;
    --rs-message-error-icon: #fff;
    --rs-message-error-bg: #f44336;
    --rs-tooltip-text: #fff;
    --rs-progress-bg: #292d33;
    --rs-sidenav-default-bg: transparent;
    --rs-progress-bar: var(--rs-primary-400);
    --rs-progress-bar-success: #4caf50;
    --rs-progress-bar-fail: #f44336;
    --rs-placeholder: #3c3f43;
    --rs-placeholder-active: #484c50;
    --rs-dropdown-divider: #3c3f43;
    --rs-dropdown-item-bg-hover: #3c3f43;
    --rs-dropdown-item-bg-active: rgba(0, 97, 153, 0.2);
    --rs-dropdown-item-text-active: var(--rs-primary-400);
    --rs-dropdown-header-text: #5c6066;
    --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2),
      0 4px 4px 3px rgba(0, 0, 0, 0.24);
    --rs-menuitem-active-bg: #3c3f43;
    --rs-menuitem-active-text: currentColor;
    --rs-steps-border: #a4a9b3;
    --rs-steps-state-finish: var(--rs-primary-400);
    --rs-steps-border-state-finish: var(--rs-primary-400);
    --rs-steps-state-wait: #a4a9b3;
    --rs-steps-state-process: var(--rs-primary-400);
    --rs-steps-state-error: var(--rs-primary-400);
    --rs-steps-border-state-error: var(--rs-primary-400);
    --rs-steps-icon-state-process: var(--rs-primary-400);
    --rs-steps-icon-state-error: var(--rs-primary-400);
    --rs-sidenav-default-selected-text: #e7e6ff;
    --rs-navs-text: #a4a9b3;
    --rs-navs-text-hover: #fff;
    --rs-navs-bg-hover: var(--rs-primary-400);
    --rs-navs-text-active: #fff;
    --rs-navs-bg-active: var(--rs-primary-400);
    --rs-navs-tab-border: var(--rs-primary-400);
    --rs-navs-subtle-border: var(--rs-primary-400);
    --rs-navs-selected: #fff;
    --rs-navbar-default-bg: #1a1d24;
    --rs-navbar-default-text: #a4a9b3;
    --rs-navbar-default-selected-text: #de3d68;
    --rs-navbar-default-hover-bg: #292d33;
    --rs-navbar-default-hover-text: #e9ebf0;
    --rs-navbar-inverse-bg: #169de0;
    --rs-navbar-inverse-text: #fff;
    --rs-navbar-inverse-selected-bg: #59d0ff;
    --rs-navbar-inverse-hover-bg: #25b3f5;
    --rs-navbar-subtle-bg: transparent;
    --rs-navbar-subtle-text: #a4a9b3;
    --rs-navbar-subtle-selected-text: var(--rs-primary-400);
    --rs-navbar-subtle-hover-bg: #292d33;
    --rs-navbar-subtle-hover-text: #e9ebf0;
    --rs-input-bg: var(--rs-gray-900);
    --rs-input-focus-border: var(--rs-primary-400);
    --rs-input-disabled-bg: var(--rs-gray-800);
    --rs-listbox-option-hover-bg: rgba(15, 19, 25);
    --rs-listbox-option-hover-text: currentColor;
    --rs-listbox-option-selected-text: var(--rs-primary-400);
    --rs-listbox-option-selected-bg: rgba(0, 97, 153, 0.2);
    --rs-listbox-option-disabled-text: #5c6066;
    --rs-listbox-option-disabled-selected-text: #a6e9ff;
    --rs-checkbox-icon: #1a1d24;
    --rs-checkbox-border: #6a6f76;
    --rs-checkbox-checked-bg: var(--rs-primary-400);
    --rs-checkbox-disabled-bg: #5c6066;
    --rs-radio-marker: #1a1d24;
    --rs-radio-border: #6a6f76;
    --rs-radio-checked-bg: var(--rs-primary-400);
    --rs-radio-disabled-bg: #5c6066;
    --rs-toggle-bg: #6a6f76;
    --rs-toggle-thumb: #fff;
    --rs-toggle-hover-bg: #858b94;
    --rs-toggle-disabled-bg: #3c3f43;
    --rs-toggle-disabled-thumb: #5c6066;
    --rs-toggle-checked-hover-bg: #25b3f5;
    --rs-toggle-checked-disabled-bg: #006199;
    --rs-toggle-checked-disabled-thumb: #858b94;
    --rs-slider-bar: #3c3f43;
    --rs-slider-hover-bar: #3c3f43;
    --rs-slider-thumb-border: var(--rs-primary-400);
    --rs-slider-thumb-bg: #292d33;
    --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 195, 255, 0.25);
    --rs-slider-progress: var(--rs-primary-400);
    --rs-uploader-item-bg: #858b94;
    --rs-uploader-item-hover-bg: #3c3f43;
    --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
    --rs-uploader-dnd-bg: #292d33;
    --rs-uploader-dnd-border: #a4a9b3;
    --rs-uploader-dnd-hover-border: var(--rs-primary-400);
    --rs-avatar-bg: #6a6f76;
    --rs-avatar-text: #fff;
    --rs-badge-bg: #f44336;
    --rs-badge-text: #fff;
    --rs-tag-bg: #3c3f43;
    --rs-tag-close: #f44336;
    --rs-carousel-bg: #3c3f43;
    --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
    --rs-carousel-indicator-hover: #fff;
    --rs-carousel-indicator-active: var(--rs-primary-400);
    --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
      0 0 10px rgba(0, 0, 0, 0.06);
    --rs-list-bg: #0f131a;
    --rs-list-border: #292d33;
    --rs-list-hover-bg: #3c3f43;
    --rs-list-placeholder-bg: rgba(0, 97, 153, 0.2);
    --rs-list-placeholder-border: var(--rs-primary-400);
    --rs-timeline-indicator-bg: #5c6066;
    --rs-timeline-indicator-active-bg: var(--rs-primary-400);
    --rs-table-shadow: rgba(9, 9, 9, 0.99);
    --rs-table-sort: var(--rs-primary-400);
    --rs-table-resize: var(--rs-primary-400);
    --rs-table-scrollbar-track: #292d33;
    --rs-table-scrollbar-thumb: #a4a9b3;
    --rs-table-scrollbar-thumb-active: #cbced4;
    --rs-table-scrollbar-vertical-track: #292d33;
    --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
      0 0 10px rgba(0, 0, 0, 0.06);
    --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
      0 0 10px rgba(0, 0, 0, 0.06);
    --rs-form-errormessage-text: #fff;
    --rs-form-errormessage-bg: #f44336;
    --rs-form-errormessage-border: #f44336;
    --rs-picker-value: var(--rs-primary-400);
    --rs-picker-count: #169de0;
    --rs-calendar-today-bg: #169de0;
    --rs-calendar-today-text: #fff;
    --rs-calendar-range-bg: rgba(0, 97, 153, 0.5);
    --rs-calendar-time-unit-bg: #3c3f43;
    --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    --rs-tooltip-bg: #101319;

    .logo-light {
      display: none;
    }

    .logo-dark {
      display: block;
    }

    .twitter-picker {
      background-color: #0f131a !important;

      div {
        border-color: #0f131a !important;
      }
    }

    .rs-tooltip-notification {
      padding: 10px;
      background-color: #0f131a;
      border: 1px solid #404247;
      max-width: 400px !important;

      @media screen and (max-width: 768px) {
        max-width: 320px !important;
        width: 100% !important;
      }
    }

    .portal-content-dark {
      h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: white;
      }
      h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: white;
      }
      h3 {
        display: block;
        font-size: 1.17em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: white;
      }
      h4 {
        display: block;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: white;
      }
      h5 {
        display: block;
        font-size: 0.83em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: white;
      }
      h6 {
        display: block;
        font-size: 0.67em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: white;
      }
      pre {
        display: block;
        font-family: monospace;
        white-space: pre;
        margin: 1em 0px;
        color: white;
      }
      ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        color: white;
      }
      li {
        display: list-item;
        text-align: -webkit-match-parent;
        color: white;
      }
      ol {
        list-style-type: decimal;
        color: white;
      }
    }

    .rs-tooltip.placement-bottom:after {
      display: none !important;
    }

    .reaction-selector-popover {
      .rs-popover-content {
        // first child
        :nth-child(1) {
          background-color: #0f131a !important;
        }
      }
    }

    .multi-email {
      input {
        flex: 1;
        background: transparent;
      }

      span {
        color: #a4a9b3;
      }
    }
  }
}

.rs-btn-primary,
.rs-btn-primary:hover,
.rs-btn-primary:focus {
  color: #ffffff;
}

.rs-btn.rs-btn-primary.rs-btn-lg {
  height: 52px;
}

.rs-btn-ghost.rs-btn-lg {
  height: 52px;
}

.rs-input {
  height: 52px;
}

.rs-table-cell,
.rs-table-row-header {
  background-color: transparent !important;
}

.dark .dark\:block {
  display: block !important;
}

.rs-modal-full {
  width: 100% !important;
}

.rs-modal-wrapper {
  display: flex;
  margin-top: 0.5rem;
  justify-content: center;
  border-radius: 10px !important;

  .rs-modal {
    .rs-modal-dialog {
      max-width: 100% !important;
      max-height: 80vh;
    }

    .rs-modal-content {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  padding: 0;
  cursor: pointer;
  padding-right: 10px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  /* Change the color to your own background color */
  -webkit-text-fill-color: #000;
  -webkit-background-clip: text !important;
}

.dark {
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #121226 inset;
    /* Change the color to your own background color */
    -webkit-text-fill-color: #fff;
    -webkit-background-clip: text !important;
  }
}

.rs-nav-item-content,
.rs-dropdown-toggle {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem !important;

  div {
    margin-right: 3px;
  }
}

.rs-progress-info-status {
  font-size: 15px !important;
}

.rs-sidenav-item,
.rs-dropdown-item {
  display: flex;
  align-items: center;
  margin-left: 0;
  padding-left: 1.3rem !important;

  div {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.rs-dropdown-item {
  padding-left: 2rem !important;
}

.rs-drawer-wrapper {
  .rs-drawer-content {
    overflow-y: auto;
  }

  div[role="dialog"] {
    max-width: 100vw !important;
  }
}

.rs-picker-toggle.rs-btn {
  line-height: 37px;
}

.rs-picker-toggle-clean .rs-btn-close .rs-picker-toggle-caret {
  top: 17px !important;
  margin-top: 7px;
}

.rs-loader-content {
  z-index: 51 !important;
}

.rs-loader-spin {
  z-index: 52;
}

.rs-loader {
  z-index: 51;
}

.rs-loader-backdrop {
  z-index: 50;
  height: 120% !important;
}

.rs-picker-value-count {
  height: 20px !important;
  margin-top: 8px !important;
}

.rs-nav-item-active {
  background-color: var(--rs-primary-400);
  border-radius: 40px !important;
  text-align: center;
}

.rs-nav-item {
  border-radius: 40px !important;
  text-align: center;
  margin-left: 2px;
}

.rs-tag-text {
  word-break: normal !important;
}

.rs-tag-tiny {
  font-size: 10px !important;
}

.rs-picker-search-input {
  padding-top: 10px !important;
}

.rs-picker-toggle-caret {
  top: 15px !important;
}

.rs-picker-toggle-clean {
  top: 16px !important;
}

.rs-theme-dark {
  .atom-modal {
    background-color: rgb(16, 19, 25) !important;
  }

  .atom-suggestion {
    background-color: rgb(16, 19, 25) !important;
  }
}

.rs-picker-toggle {
  height: 50px !important;
}

.rs-input-number-touchspin-up {
  height: 35px !important;
}

.rs-picker-toggle-value {
  .dark & {
    color: #fff !important;
  }

  color: #575757 !important;
}

.rs-picker-menu {
  z-index: 9999 !important;
}

.rs-picker-caret-icon {
  margin-top: 8px;
}

.rs-picker-clean {
  margin-top: 8px;
}

.rs-offer-picker {
  .rs-picker-toggle {
    height: 52px !important;
  }
}

.rs-tooltip {
  border-radius: 8px !important;
}

.rs-text {
  font-family: "Montserrat", sans-serif !important;
}

.rs-picker-popup {
  z-index: 10 !important;
}

.rs-drawer-open .rs-picker-popup,
.rs-modal-open .rs-picker-popup {
  z-index: 1055 !important;
}

.rs-inline-edit-controls {
  z-index: 10 !important;
}

.rs-inline-edit {
  &-controls {
    z-index: 10 !important;
  }
}

.input-check-users {
  .rs-checkbox-control [type="checkbox"] {
    top: 12px !important;
  }
}
