.transition-transform {
  transition: transform 0.3s cubic-bezier(0.79, 0.66, 0.28, 0.93);
}
.transition-all {
  transition: all 0.3s cubic-bezier(0.79, 0.66, 0.28, 0.93);
}

.full-width {
  animation: full-width 0.3s;
}
@keyframes full-width {
  from {
    width: 20%;
  }

  to {
    width: 100%;
  }
}

.no-width {
  animation: no-width 0.5s;
}
@keyframes no-width {
  from {
    width: 100%;
  }

  to {
    width: 20%;
  }
}

.flashing-icon {
  animation: flashing-icon 1.5s;
}
@keyframes flashing-icon {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  50% {
    opacity: 1;
    transform: translateY(-100%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.step-in {
  animation: step-in 0.7s;
}

@keyframes step-in {
  0% {
    opacity: 0;
    transform: translateX(50%);
  }

  70% {
    opacity: 1;
    transform: translateX(-20%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.step-out {
  animation: step-out 0.7s;
}
@keyframes step-out {
  20% {
    transform: translateX(-20%);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

.vibrating {
  animation: vibrating cubic-bezier(0.69, 0.2, 0, 0.74) 1.3s infinite;
}
@keyframes vibrating {
  20% {
    transform: scale(1.1) rotateZ(20deg);
  }

  40% {
    transform: scale(1.3) rotateZ(-30deg);
  }

  60% {
    transform: scale(1.1) rotateZ(20deg);
  }

  80% {
    transform: scale(1.3) rotateZ(-30deg);
  }

  100% {
    transform: scale(1) rotateZ(0);
  }
}

.fadePulsing {
  animation: fadePulsing cubic-bezier(0.69, 0.2, 0, 0.74) 1.3s infinite;
}
@keyframes fadePulsing {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.talking {
  animation: talking cubic-bezier(0.69, 0.2, 0, 0.74) 0.1s infinite;
}
@keyframes talking {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.tinder-approved {
  animation: tinder-approved cubic-bezier(0.69, 0.2, 0, 0.74) 1s;
}
@keyframes tinder-approved {
  0% {
    transform: rotateZ(0deg) translate(0, 0);
    opacity: 1;
  }

  100% {
    transform: rotateZ(18deg) translate(250px, 0);
    opacity: 0;
  }
}
.tinder-rejected {
  animation: tinder-rejected cubic-bezier(0.69, 0.2, 0, 0.74) 1s;
}
@keyframes tinder-rejected {
  0% {
    transform: rotateZ(0deg) translate(0, 0);
    opacity: 1;
  }

  100% {
    transform: rotateZ(-23deg) translate(-250px, 0);
    opacity: 0;
  }
}
