@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .font-condensed {
    font-stretch: condensed;
  }

  @keyframes bounce-custom {
    0%,
    100% {
      transform: translateY(-25%) translateX(-12px); /* Ajusta según sea necesario */
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateX(-12px); /* Ajusta según sea necesario */
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .animate-bounce-custom-badge {
    animation: bounce-custom 1s infinite;
  }
}

html {
  font-family: var(--font-monserrat);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  margin-top: 8px;
}

/* Estilos para el control deslizante de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  border-radius: 16px;
}

#table-urls {
  /* Estilos para la barra de desplazamiento */
  scrollbar-width: thin; /* Para navegadores que soportan la propiedad scrollbar-width */
  scrollbar-color: auto; /* Color del control deslizante y del track */
}

#scrollable-blue {
  /* Estilos para la barra de desplazamiento */
  scrollbar-width: thin; /* Para navegadores que soportan la propiedad scrollbar-width */
  scrollbar-color: #3747f5 transparent; /* Color del control deslizante y del track */
  padding-bottom: 8px;
}

@layer base {
  /* Aplicar estilos base a todos los elementos */
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
  :root {
    --background: hsla(0 0% 100%);
    --foreground: hsla(222.2 84% 4.9%);

    --card: hsla(0 0% 100%);
    --card-foreground: hsla(222.2 84% 4.9%);

    --popover: hsla(0 0% 100%);
    --popover-foreground: hsla(222.2 84% 4.9%);

    --primary: rgb(55, 71, 245);
    --primary-foreground: hsla(210 20% 98%);

    --secondary: #eaeef4;
    --secondary-foreground: hsla(222.2 47.4% 11.2%);

    --muted: hsla(210 40% 96.1%);
    --muted-foreground: hsla(215.4 16.3% 46.9%);

    --accent: hsla(210 40% 96.1%);
    --accent-foreground: hsla(222.2 47.4% 11.2%);

    --destructive: hsla(0 84% 60%);
    --destructive-foreground: hsla(210 40% 98%);

    --border: hsla(214.3 31.8% 91.4%);
    --input: hsla(214.3 31.8% 91.4%);
    --ring: hsla(222.2 84% 4.9%);

    --radius: 0.5rem;

    --customBgActiveButtonPrimary: rgb(55, 71, 245);

    --button-nav-hover: #e5e5ea;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --BgSidebarBody: #f7f7fa;
    --BgSidebarFooter: #f7f7fa;
    --customTopShadow: 0 -2px 4px 0 rgba(14, 14, 14, 0.05);
    --customRightShadow: 2px 0 4px 0 rgba(0, 0, 0, 0.05);
    --customRightShadow: 2px 0 4px 0 rgba(0, 0, 0, 0.05);

    --BgCapsule: rgba(191, 219, 254, 0.25);

    --sidebar-width-expanded: 270px;
    --sidebar-width-collapsed: 80px;
    --sidebar-header-desktop: 79px;
    --sidebar-header-mobile: 62px;

    --max-size-grid: 1200px;
  }

  .dark {
    --white: #0f131a;
    --background: hsla(222.2 84% 4.9%);
    --foreground: hsla(210 40% 98%);
    --primary-foreground: hsla(210 40% 98%);

    --card: hsla(222.2 84% 4.9%);
    --card-foreground: hsla(210 40% 98%);

    --popover: hsla(222.2 84% 4.9%);
    --popover-foreground: hsla(210 40% 98%);

    --primary: rgb(55, 71, 245);
    --primary-foreground: hsla(210 20% 32%);

    --secondary: #1f283b;
    --secondary-foreground: hsla(210 40% 98%);

    --muted: hsla(217.2 32.6% 17.5%);
    --muted-foreground: hsla(215 20.2% 65.1%);

    --accent: hsla(217.2 32.6% 17.5%);
    --accent-foreground: hsla(210 40% 98%);

    --destructive: hsla(0 84% 60%);
    --destructive-foreground: hsla(210 40% 98%);

    --border: hsla(217.2 32.6% 17.5%);
    --input: hsla(217.2 32.6% 17.5%);
    --ring: hsla(212.7 26.8% 83.9%);

    --customBgActiveButtonPrimary: rgb(30, 64, 175);

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --BgSidebarBody: #15191e;
    --BgSidebarFooter: #15191e;
    --customTopShadow: 0 -2px 4px 0 rgba(255, 255, 255, 0.05);
    --customRightShadow: 2px 0 4px 0 rgba(0, 0, 0, 0.05);
  }
  @media (prefers-color-scheme: dark) {
    :root {
      --primary-foreground: hsla(210 40% 98%);
    }
  }
}

@keyframes custom-bounce {
  0%,
  100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(
      -20px
    ); /* Ajusta este valor según la altura deseada */
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

/* Estilos para la animación de rebote  bounce Notification badge*/
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-4px);
  }
}

.bounce-badge-notification .rs-badge-content {
  animation: bounce 1s infinite linear;
  right: -6px;
}

/* Global style align text-left default */
.rs-col {
  text-align: left;
}

/* Fix background on open injection link on text editor */
.tox .tox-dialog-wrap__backdrop--opaque {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
