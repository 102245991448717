:root {
  /* ========== Rings Dimension ========== */

  --ring3_Dimension: 50%;
  --ring2_Dimension: 85%;
  --ring2_OffDimension: -15%;
  --ring1_Dimension: 80%;
  --ring1_OffDimension: -20%;
  --ring0_Dimension: 60%;
  --ring0_OffDimension: -40%;

  /* ========== Rings Colors ========== */

  /* Quadrant Zero */

  --q0r0Bkg: #0069c0;
  --q0r1Bkg: #137fd9;
  --q0r2Bkg: #2196f3;
  --q0r3Bkg: #6bbef9;

  --q0r0BkgDark: rgba(76, 29, 149, 1);
  --q0r1BkgDark: rgba(76, 29, 149, 0.9);
  --q0r2BkgDark: rgba(76, 29, 149, 0.8);
  --q0r3BkgDark: rgba(76, 29, 149, 0.7);

  /* Quadrant One */

  --q1r0Bkg: #0069c0;
  --q1r1Bkg: #137fd9;
  --q1r2Bkg: #2196f3;
  --q1r3Bkg: #6bbef9;

  --q1r0BkgDark: rgba(76, 29, 149, 1);
  --q1r1BkgDark: rgba(76, 29, 149, 0.9);
  --q1r2BkgDark: rgba(76, 29, 149, 0.8);
  --q1r3BkgDark: rgba(76, 29, 149, 0.7);

  /* Quadrant Two */

  --q2r0Bkg: #0069c0;
  --q2r1Bkg: #137fd9;
  --q2r2Bkg: #2196f3;
  --q2r3Bkg: #6bbef9;

  --q2r0BkgDark: rgba(76, 29, 149, 1);
  --q2r1BkgDark: rgba(76, 29, 149, 0.9);
  --q2r2BkgDark: rgba(76, 29, 149, 0.8);
  --q2r3BkgDark: rgba(76, 29, 149, 0.7);

  /* Quadrant Three */

  --q3r0Bkg: #0069c0;
  --q3r1Bkg: #137fd9;
  --q3r2Bkg: #2196f3;
  --q3r3Bkg: #6bbef9;

  --q3r0BkgDark: rgba(76, 29, 149, 1);
  --q3r1BkgDark: rgba(76, 29, 149, 0.9);
  --q3r2BkgDark: rgba(76, 29, 149, 0.8);
  --q3r3BkgDark: rgba(76, 29, 149, 0.7);

  /* ========== Elements Colors ========== */

  /* Background Quadrants */

  --quadrantBck: #edf1f3;
  --quadrantBckDark: #201f37;

  /* Default */

  --eleBkg: #fafafa;
  --eleCol: #000;
  --eleBrd: #333333;

  /* Default / Hover */

  --eleBkgHover: #0070f3;
  --eleColHover: white;
  --eleBrdHover: white;

  /* Quadrant Zero */

  --eleBkgQ0: #6d28d9;
  --eleColQ0: #fff;
  --eleBrdQ0: #fff;

  /* Quadrant One */

  --eleBkgQ1: #3638bc;
  --eleColQ1: #fff;
  --eleBrdQ1: #fff;

  /* Quadrant Two */

  --eleBkgQ2: #38aeba;
  --eleColQ2: #fff;
  --eleBrdQ2: #fff;

  /* Quadrant Three */

  --eleBkgQ3: #fc7125;
  --eleColQ3: #fff;
  --eleBrdQ3: #fff;

  /* ========== Quadrant Label Color ========== */

  --quadrantLabelColor: #666666;
  --quadrantLabelColorDark: #fff;

  /* ========== Ring Label Color ========== */

  --ringLabelColor: #666666;
  --ringLabelColorDark: #fff;
}

/* ========== Quadrants Schema ========== */

.background-quadrant {
  background-color: var(--quadrantBck);
}

.background-quadrant-dark {
  background-color: var(--quadrantBckDark);
}

.box {
  position: relative;
  aspect-ratio: auto 1/1;
  border: #828282 1px solid;
}

.box-dark {
  position: relative;
  aspect-ratio: auto 1/1;
  border: #fff 1px solid;
}

/* Quadrants Zero */

.quadrant-0-ring-0 {
  width: var(--ring0_Dimension);
  background-color: var(--q0r0Bkg);
  border-radius: 0% 100% 0% 0%;
  bottom: var(--ring0_OffDimension);
}

.quadrant-0-ring-0-dark {
  width: var(--ring0_Dimension);
  background-color: var(--q0r0BkgDark);
  border-radius: 0% 100% 0% 0%;
  bottom: var(--ring0_OffDimension);
}

.quadrant-0-ring-1 {
  width: var(--ring1_Dimension);
  background-color: var(--q0r1Bkg);
  border-radius: 0% 100% 0% 0%;
  bottom: var(--ring1_OffDimension);
}

.quadrant-0-ring-1-dark {
  width: var(--ring1_Dimension);
  background-color: var(--q0r1BkgDark);
  border-radius: 0% 100% 0% 0%;
  bottom: var(--ring1_OffDimension);
}

.quadrant-0-ring-2 {
  width: var(--ring2_Dimension);
  background-color: var(--q0r2Bkg);
  border-radius: 0% 100% 0% 0%;
  bottom: var(--ring2_OffDimension);
}

.quadrant-0-ring-2-dark {
  width: var(--ring2_Dimension);
  background-color: var(--q0r2BkgDark);
  border-radius: 0% 100% 0% 0%;
  bottom: var(--ring2_OffDimension);
}

.quadrant-0-ring-3 {
  width: var(--ring3_Dimension);
  background-color: var(--q0r3Bkg);
  border-radius: 0% 100% 0% 0%;
  margin: 2%;
}

.quadrant-0-ring-3-dark {
  width: var(--ring3_Dimension);
  background-color: var(--q0r3BkgDark);
  border-radius: 0% 100% 0% 0%;
  margin: 2%;
}

/* Quadrants One */

.quadrant-1-ring-0 {
  width: var(--ring0_Dimension);
  background-color: var(--q1r0Bkg);
  border-radius: 0% 0% 100% 0%;
}

.quadrant-1-ring-0-dark {
  width: var(--ring0_Dimension);
  background-color: var(--q1r0BkgDark);
  border-radius: 0% 0% 100% 0%;
}

.quadrant-1-ring-1 {
  width: var(--ring1_Dimension);
  background-color: var(--q1r1Bkg);
  border-radius: 0% 0% 100% 0%;
}

.quadrant-1-ring-1-dark {
  width: var(--ring1_Dimension);
  background-color: var(--q1r1BkgDark);
  border-radius: 0% 0% 100% 0%;
}

.quadrant-1-ring-2 {
  width: var(--ring2_Dimension);
  background-color: var(--q1r2Bkg);
  border-radius: 0% 0% 100% 0%;
}

.quadrant-1-ring-2-dark {
  width: var(--ring2_Dimension);
  background-color: var(--q1r2BkgDark);
  border-radius: 0% 0% 100% 0%;
}

.quadrant-1-ring-3 {
  width: var(--ring3_Dimension);
  background-color: var(--q1r3Bkg);
  border-radius: 0% 0% 100% 0%;
  margin: 2%;
}

.quadrant-1-ring-3-dark {
  width: var(--ring3_Dimension);
  background-color: var(--q1r3BkgDark);
  border-radius: 0% 0% 100% 0%;
  margin: 2%;
}

/* Quadrants Two */

.quadrant-2-ring-0 {
  width: var(--ring0_Dimension);
  background-color: var(--q2r0Bkg);
  border-radius: 0% 0% 0% 100%;
  right: var(--ring0_OffDimension);
}

.quadrant-2-ring-0-dark {
  width: var(--ring0_Dimension);
  background-color: var(--q2r0BkgDark);
  border-radius: 0% 0% 0% 100%;
  right: var(--ring0_OffDimension);
}

.quadrant-2-ring-1 {
  width: var(--ring1_Dimension);
  background-color: var(--q2r1Bkg);
  border-radius: 0% 0% 0% 100%;
  right: var(--ring1_OffDimension);
}

.quadrant-2-ring-1-dark {
  width: var(--ring1_Dimension);
  background-color: var(--q2r1BkgDark);
  border-radius: 0% 0% 0% 100%;
  right: var(--ring1_OffDimension);
}

.quadrant-2-ring-2 {
  width: var(--ring2_Dimension);
  background-color: var(--q2r2Bkg);
  border-radius: 0% 0% 0% 100%;
  right: var(--ring2_OffDimension);
}

.quadrant-2-ring-2-dark {
  width: var(--ring2_Dimension);
  background-color: var(--q2r2BkgDark);
  border-radius: 0% 0% 0% 100%;
  right: var(--ring2_OffDimension);
}

.quadrant-2-ring-3 {
  width: var(--ring3_Dimension);
  background-color: var(--q2r3Bkg);
  border-radius: 0% 0% 0% 100%;
  margin: 2%;
}

.quadrant-2-ring-3-dark {
  width: var(--ring3_Dimension);
  background-color: var(--q2r3BkgDark);
  border-radius: 0% 0% 0% 100%;
  margin: 2%;
}

/* Quadrants Three */

.quadrant-3-ring-0 {
  width: var(--ring0_Dimension);
  background-color: var(--q3r0Bkg);
  border-radius: 100% 0% 0% 0%;
  right: var(--ring0_OffDimension);
  bottom: var(--ring0_OffDimension);
}

.quadrant-3-ring-0-dark {
  width: var(--ring0_Dimension);
  background-color: var(--q3r0BkgDark);
  border-radius: 100% 0% 0% 0%;
  right: var(--ring0_OffDimension);
  bottom: var(--ring0_OffDimension);
}

.quadrant-3-ring-1 {
  width: var(--ring1_Dimension);
  background-color: var(--q3r1Bkg);
  border-radius: 100% 0% 0% 0%;
  right: var(--ring1_OffDimension);
  bottom: var(--ring1_OffDimension);
}

.quadrant-3-ring-1-dark {
  width: var(--ring1_Dimension);
  background-color: var(--q3r1BkgDark);
  border-radius: 100% 0% 0% 0%;
  right: var(--ring1_OffDimension);
  bottom: var(--ring1_OffDimension);
}

.quadrant-3-ring-2 {
  width: var(--ring2_Dimension);
  background-color: var(--q3r2Bkg);
  border-radius: 100% 0% 0% 0%;
  right: var(--ring2_OffDimension);
  bottom: var(--ring2_OffDimension);
}

.quadrant-3-ring-2-dark {
  width: var(--ring2_Dimension);
  background-color: var(--q3r2BkgDark);
  border-radius: 100% 0% 0% 0%;
  right: var(--ring2_OffDimension);
  bottom: var(--ring2_OffDimension);
}

.quadrant-3-ring-3 {
  width: var(--ring3_Dimension);
  background-color: var(--q3r3Bkg);
  border-radius: 100% 0% 0% 0%;
  margin: 2%;
}

.quadrant-3-ring-3-dark {
  width: var(--ring3_Dimension);
  background-color: var(--q3r3BkgDark);
  border-radius: 100% 0% 0% 0%;
  margin: 2%;
}

/* ========== Quadrants Labels ========== */

.label-white {
  display: flex;
  background-color: transparent;
  color: var(--quadrantLabelColor);
  font-size: 18px;
}

.label-dark {
  display: flex;
  background-color: transparent;
  color: var(--quadrantLabelColorDark);
  font-size: 18px;
}

.label div,
.label-dark div {
  margin-top: 5%;
}

.label-q0 {
  justify-content: right;
  margin-bottom: -5%;
  padding-right: 16px;
  width: 100%;
  bottom: 0px;
}

.label-q1 {
  justify-content: right;
  margin-top: -5%;
  padding-right: 16px;
  width: 100%;
}

.label-q2 {
  justify-content: left;
  margin-top: -5%;
  padding-left: 16px;
  width: 100%;
}

.label-q3 {
  justify-content: left;
  margin-bottom: -5%;
  padding-left: 16px;
  width: 100%;
}

/* ========== Rings Labels ========== */

.ring-labels {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-left: 2%;
  margin-right: 2%;
  color: var(--ringLabelColor);
}

.ring-labels-dark {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-left: 2%;
  margin-right: 2%;
  color: var(--ringLabelColorDark);
}

.r-left {
  text-align: left;
  justify-content: center;
}

.r-right {
  text-align: right;
  justify-content: center;
}

.r-label {
  display: flex;
  text-align: center;
  font-size: 14px;
}

.r-label-0 {
  width: 32%;
}

.r-label-1 {
  width: 20%;
}

.r-label-2 {
  width: 12%;
}

.r-label-3 {
  width: 11%;
}

/* ========== Elements Style ========== */

.circle-element {
  background-color: var(--eleBkg);
  color: var(--eleCol);
  border: 1px solid var(--eleBrd);
  min-width: 25px;
  min-height: 24px;
  border-radius: 50%;
  padding: 5px;
  font-size: 10px;
  position: absolute;
  text-align: center;
  z-index: 9;
}

.circle-element:hover {
  background-color: var(--eleBkgHover);
  color: var(--eleColHover);
  border: 1px solid var(--eleBrdHover);
  cursor: pointer;
}

.ce-q0 {
  background-color: var(--eleBkgQ0);
  color: var(--eleColQ0);
  border: 1px solid var(--eleBrdQ0);
}

.ce-q1 {
  background-color: var(--eleBkgQ1);
  color: var(--eleColQ1);
  border: 1px solid var(--eleBrdQ1);
}

.ce-q2 {
  background-color: var(--eleBkgQ2);
  color: var(--eleColQ2);
  border: 1px solid var(--eleBrdQ2);
}

.ce-q3 {
  background-color: var(--eleBkgQ3);
  color: var(--eleColQ3);
  border: 1px solid var(--eleBrdQ3);
}
