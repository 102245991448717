@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;700&display=swap");
@import "~swiper/css";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";
@import "~swiper/css/scrollbar";

html {
  font-family: var(--font-monserrat);
}

:root {
  --swiper-navigation-size: 32px;
}

* {
  font-family: "Montserrat", sans-serif;
}

.tox .tox-toolbar__group:not(:first-child) .tox-tbtn__select-label {
  font-family: "Montserrat", sans-serif !important;
}

.tox-mbtn__select-label {
  font-family: "Montserrat", sans-serif !important;
}

.tox-collection__item-label {
  font-family: "Montserrat", sans-serif !important;
}

// html selector has been removed to avoid tagpicker and selectpicker dropdown issues USER STORY 29671 Análisis Bug - error visual dropdown de <TagPicker />
body,
#__next,
html {
  height: 100vh;
  height: -webkit-fill-available;
}
a {
  text-decoration: none !important;
}
label {
  display: block;
  margin-bottom: 0.2rem;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox 64 */
}
::-webkit-scrollbar {
  display: none;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

/* SPLIT PANEL */

.split-horizontal {
  display: flex;
  width: 100%;
  height: 100%;
}
.split-vertical {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dark {
  .gutter {
    background: rgb(44, 44, 44);
  }
}
.light {
  .gutter {
    background: lightgray;
  }
}

.gutter {
  flex-shrink: 0;
  flex-grow: 0;
  background: gray;
}
.gutter-horizontal {
  cursor: col-resize;
}
.gutter-vertical {
  cursor: row-resize;
}
.gutter:hover {
  background: #3f33ff;
}
.gutter-dragging:hover {
  background: #3f33ff;
}

.pane {
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}
.scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.tree-light-link {
  stroke: #fff !important;
}
.tree-dark-link {
  stroke: #000 !important;
}

.rce-mbox-text {
  color: black;
}

.rce-mbox-forward .rce-mbox-reply-btn-left {
  fill: #000;
}

.dark {
  .rce-mbox-forward > svg {
    fill: #000;
  }
}

.ignore-css {
  h1 {
    display: grid;
    font-size: 2em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: black;
    line-height: normal;
  }
  h2 {
    display: grid;
    font-size: 1.5em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: black;
    line-height: normal;
  }
  h3 {
    display: grid;
    font-size: 1.17em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: black;
    line-height: normal;
  }
  h4 {
    display: grid;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: black;
    line-height: normal;
  }
  h5 {
    display: grid;
    font-size: 0.83em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: black;
    line-height: normal;
  }
  h6 {
    display: grid;
    font-size: 0.67em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: black;
    line-height: normal;
  }
  pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0px;
    color: black;
    border-radius: 10px !important;
  }
  ul {
    display: grid;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    color: black;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
    color: black;
  }
  ol {
    list-style-type: decimal;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    color: black;
    line-height: normal;
  }
  color: black;
}

.dark {
  .ignore-css {
    h1 {
      display: grid;
      font-size: 2em;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      color: white;
    }
    h2 {
      display: grid;
      font-size: 1.5em;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      color: white;
    }
    h3 {
      display: grid;
      font-size: 1.17em;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      color: white;
    }
    h4 {
      display: grid;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      color: white;
    }
    h5 {
      display: grid;
      font-size: 0.83em;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      color: white;
    }
    h6 {
      display: grid;
      font-size: 0.67em;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      color: white;
    }

    pre {
      display: block;
      font-family: monospace;
      white-space: pre;
      margin: 1em 0px;
      color: white;
      border-radius: 10px !important;
    }
    ul {
      display: grid;
      list-style-type: disc;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      color: white;
    }
    li {
      display: list-item;
      text-align: -webkit-match-parent;
      color: white;
    }
    ol {
      list-style-type: decimal;
      margin-block-start: 0em;
      margin-block-end: 0em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      color: black;
      line-height: normal;
    }
    color: white;
  }
}

.rs-picker-toolbar-right-btn-ok {
  color: white;

  :hover {
    color: white;
  }
}

.event-form-map {
  width: 100%;
  height: 200px;
}

.markdown-code {
  ul li {
    list-style-type: disc;
  }
}

.flex-container {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-item {
  padding: 5px;
  width: 100%;
  margin: 10px;
  line-height: 5px;
  color: white;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.font-medium {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.force-bg-transparent div {
  background-color: transparent !important;
  box-shadow: none !important;
}

.square-full {
  width: 100%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .break-all-word {
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

.SidePane_sidePane__2RWR- {
  z-index: 10 !important;
}

.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.bubble {
  position: relative;
  width: 400px;
  height: 250px;
  padding: 0px;
  background: #ffffff;
  border: #000 solid 1px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-left: 40px;
}

.bubble:before {
  content: "";
  position: absolute;
  top: calc(50% - 26px);
  left: -26px;
  border-style: solid;
  border-width: 26px 26px 26px 0;
  border-color: transparent #000;
  width: 0;
}

.swiper {
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
}
.swiper-button-prev {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20transform%3D%22rotate%28180%29%22%20width%3D%22512pt%22%20height%3D%22512pt%22%20viewBox%3D%220%200%20512%20512%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20id%3D%22%23ffffff%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20opacity%3D%221.00%22%20d%3D%22%20M%20241.41%2016.47%20C%20280.12%2014.04%20319.37%2021.26%20354.67%2037.29%20C%20410.73%2062.45%20456.26%20110.00%20478.86%20167.14%20C%20495.82%20209.31%20500.31%20256.36%20491.71%20300.99%20C%20484.80%20337.43%20469.24%20372.19%20446.71%20401.65%20C%20428.27%20425.83%20405.18%20446.47%20379.05%20462.03%20C%20342.97%20483.65%20301.10%20495.50%20259.03%20495.94%20C%20219.62%20496.50%20180.12%20487.08%20145.19%20468.84%20C%20116.82%20454.12%2091.50%20433.60%2071.12%20408.99%20C%2047.93%20381.05%2031.26%20347.75%2022.75%20312.45%20C%2012.75%20271.35%2013.90%20227.60%2026.12%20187.10%20C%2039.91%20140.80%2068.09%2098.93%20105.86%2068.80%20C%20144.21%2037.94%20192.26%2019.33%20241.41%2016.47%20M%20210.11%20168.06%20C%20206.24%20174.50%20207.92%20183.49%20213.70%20188.25%20C%20239.12%20210.82%20264.52%20233.43%20289.94%20256.00%20C%20264.71%20278.40%20239.51%20300.85%20214.26%20323.24%20C%20211.28%20325.76%20208.70%20329.05%20208.13%20333.01%20C%20206.60%20342.96%20216.08%20352.69%20226.01%20351.84%20C%20229.32%20351.58%20232.35%20349.96%20234.81%20347.80%20C%20263.23%20322.57%20291.62%20297.31%20320.03%20272.07%20C%20323.36%20269.09%20327.21%20266.24%20328.77%20261.87%20C%20331.28%20255.68%20329.39%20248.13%20324.34%20243.79%20C%20295.92%20218.47%20267.45%20193.22%20239.01%20167.92%20C%20235.76%20165.08%20232.64%20161.69%20228.31%20160.59%20C%20221.46%20158.57%20213.55%20161.80%20210.11%20168.06%20Z%22%20%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E") !important;

  height: 32px !important;
  width: 32px !important;
  margin-top: calc(0px - 36px) !important;
  background-size: cover;
  &:after {
    content: "" !important;
  }
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20width%3D%22512pt%22%20height%3D%22512pt%22%20viewBox%3D%220%200%20512%20512%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20id%3D%22%23ffffff%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20opacity%3D%221.00%22%20d%3D%22%20M%20241.41%2016.47%20C%20280.12%2014.04%20319.37%2021.26%20354.67%2037.29%20C%20410.73%2062.45%20456.26%20110.00%20478.86%20167.14%20C%20495.82%20209.31%20500.31%20256.36%20491.71%20300.99%20C%20484.80%20337.43%20469.24%20372.19%20446.71%20401.65%20C%20428.27%20425.83%20405.18%20446.47%20379.05%20462.03%20C%20342.97%20483.65%20301.10%20495.50%20259.03%20495.94%20C%20219.62%20496.50%20180.12%20487.08%20145.19%20468.84%20C%20116.82%20454.12%2091.50%20433.60%2071.12%20408.99%20C%2047.93%20381.05%2031.26%20347.75%2022.75%20312.45%20C%2012.75%20271.35%2013.90%20227.60%2026.12%20187.10%20C%2039.91%20140.80%2068.09%2098.93%20105.86%2068.80%20C%20144.21%2037.94%20192.26%2019.33%20241.41%2016.47%20M%20210.11%20168.06%20C%20206.24%20174.50%20207.92%20183.49%20213.70%20188.25%20C%20239.12%20210.82%20264.52%20233.43%20289.94%20256.00%20C%20264.71%20278.40%20239.51%20300.85%20214.26%20323.24%20C%20211.28%20325.76%20208.70%20329.05%20208.13%20333.01%20C%20206.60%20342.96%20216.08%20352.69%20226.01%20351.84%20C%20229.32%20351.58%20232.35%20349.96%20234.81%20347.80%20C%20263.23%20322.57%20291.62%20297.31%20320.03%20272.07%20C%20323.36%20269.09%20327.21%20266.24%20328.77%20261.87%20C%20331.28%20255.68%20329.39%20248.13%20324.34%20243.79%20C%20295.92%20218.47%20267.45%20193.22%20239.01%20167.92%20C%20235.76%20165.08%20232.64%20161.69%20228.31%20160.59%20C%20221.46%20158.57%20213.55%20161.80%20210.11%20168.06%20Z%22%20%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E") !important;
  height: 32px !important;
  width: 32px !important;
  margin-top: calc(0px - 36px) !important;
  background-size: cover;
  &:after {
    content: "" !important;
  }
}

.canvasWidget {
  svg {
    overflow: visible;
  }
}

.diagram-container {
  background: #333333;
  width: 100%;
  height: 100%;
}

.custom-node {
  border: solid 2px gray;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.custom-node-color {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.circle-port {
  width: 12px;
  height: 12px;
  margin: 2px;
  border-radius: 4px;
  background: darkgray;
  cursor: pointer;
}

.circle-port:hover {
  background: mediumpurple;
}

.question-table-row {
  min-height: 70px !important;
}

.multi-email {
  input {
    flex: 1;
    background: transparent;
  }
  span {
    color: #8e8e93;
  }
}

.new-notification {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 14px 6px 0;
  background-color: #3ea6ff;
}

.like-separator {
  width: 3px;
  height: 3px;
  border-radius: 2px;
  margin: 0px 6px 0;
}

.seen-notification {
  width: 8px;
  height: 4px;
  border-radius: 2px;
  margin: 14px 6px 0;
}

.notification-text-date {
  font-size: 0.65rem;
  line-height: 0;
  color: #3ea6ff;
}

.rs-navbar-mobile {
  display: block !important;
  white-space: normal !important;
}

.rs-user-badge {
  padding: 0 !important;
  // * backgroud color transparent
  background-color: transparent !important;
  // * filter none
  filter: none !important;
  // * delete box shadow
  box-shadow: none !important;
  // * remove after element
  &:after {
    content: none !important;
  }
  .rs-popover-arrow {
    display: none !important;
  }
}

input[id^="rc-editable-input"] {
  background-color: #f7fafc;
}

.profile-background {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

._cCkxB {
  :hover {
    background-color: #3ea6ff !important;
    width: 100% !important;
    // * border rounded
    border-radius: 8px !important;
  }
}

._is6ww {
  p {
    width: 670px !important;
  }
}

@media screen and (max-width: 767px) {
  ._is6ww {
    p {
      width: 350px !important;
    }
  }
}

.atom-trigger {
  display: none !important;
}

.css-vbnk1l {
  color: #3f33ff !important;
}

.atom-suggestionsContainerOpen {
  overflow: auto !important;
}

.ReactModal__Overlay {
  z-index: 9999 !important;
}

.video-explore {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.fix-thread-message {
  margin-bottom: -30px !important;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.animate__float {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

.speech-bubble {
  position: relative;
  background: #4200bd;
  border-radius: 0.4em;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 29px solid transparent;
  border-top-color: #4200bd;
  border-bottom: 0;
  border-left: 0;
  margin-left: -14.5px;
  margin-bottom: -29px;
}

.mentions-portal {
  z-index: 9999 !important;
}

.comment-feedback {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}

.anonymous-comment-feedback {
  width: 100%;
  display: flex;
  height: 280px;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  max-height: 380px;
  box-shadow: 0px 0px 10px #ccc;
}

.anonymous-content-feedback {
  flex: 1;
  overflow-y: auto;
  max-height: 200px;
  word-wrap: break-word;
}

.left-feedback-col {
  margin-right: 20px;
}

.meta-feedback-created,
.meta-feedback-date {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-feedback {
  flex: 1;
  overflow-y: auto;
  max-height: 200px;
  word-wrap: break-word;
}

.content-feedback p {
  text-align: center;
  width: 100%;
}

.meta-feedback {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  .left-feedback-col {
    display: none;
  }

  .meta-feedback {
    align-items: center;
    word-wrap: break-word;
  }
}

@media screen and (min-width: 767px) {
  .bottom-feedback-row {
    display: none;
  }
}

.tox .tox-toolbar__group:not(:first-child) .tox-tbtn__select-label {
  font-family: "Montserrat", sans-serif !important;
}

.tox-mbtn__select-label {
  font-family: "Montserrat", sans-serif !important;
}

.tox-collection__item-label {
  font-family: "Montserrat", sans-serif !important;
}

.custom-timeline {
  margin-left: 0px;

  .rs-timeline-item-custom-dot {
    .rs-icon {
      position: absolute;
      background: #fff;
      top: 0;
      left: -2px;
      border: 2px solid #ddd;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 18px;
      color: #999;
      margin-left: -13px;
      justify-content: center;
      padding: 8px;
    }
  }

  .rs-timeline-item-tail {
    display: none;
  }

  .rs-timeline-item-content {
    margin-left: 24px;
  }
}

.rs-timeline-item-content p {
  margin: 0;
}
.rs-timeline-item-custom {
  margin-bottom: 1rem;
  padding: 1rem;

  .rs-timeline-item {
    margin-left: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }
}
.activity-card-explore {
  border-radius: 8px !important;

  // all iframe childs
  iframe {
    border-radius: 8px !important;
  }
}

.global-ranking {
  @media (max-width: 991px) {
    margin-top: 20px;
  }
}

.nabvar-search-input {
  border-radius: 10px;

  .rs-picker-toggle {
    border-radius: 10px !important;
    padding-left: 25px;
    padding-right: 20px;
    height: 50px;
  }

  .rs-picker-search-input {
    height: 50px;
    margin-left: 10px;
  }

  .rs-picker-toggle-caret {
    display: none !important;
  }

  .rs-picker-caret-icon {
    display: none !important;
  }
}

.notification-full-width {
  width: 100% !important;
  max-width: 100% !important;

  .rs-notification-content {
    width: 100% !important;
    max-width: 100% !important;
  }

  .rs-notification-description {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.RMM__close-button {
  left: 20px !important;
  bottom: 20px !important;
}

.skill-component {
  li {
    display: flex;
    justify-content: "end";
    width: 100% !important;
    height: 42px;
  }
}

.transition-colors {
  height: fit-content !important;
}

.invitedUser {
  margin: 0 0 0 1rem;
  display: flex;
  gap: -15;
  .invitedUser-item {
    margin-left: -8px;
    z-index: 2;
  }
}

.rs-content-fixer {
  .rs-table-cell-content {
    padding: 10px 10px !important;
  }
}

.infinitescroll-overflow-hidden {
  overflow: hidden !important;
}

.portal-layout {
  background-image: url("./../../public/svg/layout.svg");
  background-repeat: no-repeat;
}

.portal-apply-card-bg {
  background-image: url("./../../public/svg/layout.svg");
  background-size: auto;
  background-color: #6a35ff;
  background-position: initial;
}

.portal-offer-subtitle {
  color: #6a35ff;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 10px;
}

.portal-offer-content {
  @media only screen and (min-width: 480px) {
    padding-left: 130px;
    padding-right: 130px;
  }
}

.portal-offer-wrapper {
  display: flex;
  flex-flow: row;

  @media only screen and (max-width: 1024px) {
    flex-flow: column;
  }
  .portal-offer-right-panel {
    padding-right: 60px;
    @media only screen and (max-width: 1024px) {
      padding-right: 0px;
    }
  }

  .portal-right-container {
    max-width: 350px;
  }
}

.portal-offer-job-description {
  margin-bottom: 10px;
  color: #7b88a8;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 400;
}

.portal-content-light {
  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h4 {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h5 {
    display: block;
    font-size: 0.83em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h6 {
    display: block;
    font-size: 0.67em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
  ol {
    list-style-type: decimal;
  }
}

.portal-content-dark {
  h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
  }
  h4 {
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
  }
  h5 {
    display: block;
    font-size: 0.83em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
  }
  h6 {
    display: block;
    font-size: 0.67em;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
  }
  pre {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0px;
    color: white;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    color: white;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
    color: white;
  }
  ol {
    list-style-type: decimal;
    color: white;
  }
}

.groow-search-input {
  height: 50px !important;
  .rs-picker-toggle {
    height: 50px !important;
  }
}

.pulse {
  animation: pulse 1.5s infinite;
  background-color: rgba(255, 82, 82, 1);
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.dark-message {
  .rce-mbox {
    background: #cce9ff !important;
  }
}

/* Definición de variables para opacidad en modos claros y oscuros */
$borderOpacityLight: rgba(255, 255, 255, 0.2);
$borderOpacityDark: rgba(0, 0, 0, 0.2);

/* Estilos para el modo claro */
@media (prefers-color-scheme: light) {
  .border-opacity-20 {
    border-color: $borderOpacityLight; /* Utilizando la variable de opacidad para modo claro */
  }
}

/* Estilos para el modo oscuro */
@media (prefers-color-scheme: dark) {
  .border-opacity-20 {
    border-color: $borderOpacityDark; /* Utilizando la variable de opacidad para modo oscuro */
  }
}

.RMM__body {
  padding: 0px !important;
}

.bounce-badge-notification {
  .rs-badge-content {
    animation: bounce 1s infinite;
  }
}

.fix-sidebar-height {
  margin-top: -10px !important;

  @media screen and (max-width: 767px) {
    margin-top: 0px !important;
  }
}

.swal2-radio {
  background: transparent !important;
}

.user-avatar-boring {
  border-radius: 100% !important;

  svg {
    border-radius: 100% !important;
  }
}

.pro-inner-list-item {
  ul {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

.pro-sidebar-menu-tooltip {
  left: 73px !important;
}

.image-border-radius {
  img {
    border-radius: 10px !important;
  }
}

.appreciation-table {
  .rs-table-row-expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.p-rules {
  p {
    line-height: 1.5 !important;
    overflow-wrap: break-word; /* Intentará romper la palabra solo si es necesario */
    word-break: break-word; /* Rompe palabras en la línea si no caben */

    a {
      overflow-wrap: break-word; /* Intentará romper la palabra solo si es necesario */
      word-break: break-word; /* Rompe palabras en la línea si no caben */
    }
  }
}

// Fix Global react-select styles
.rs-picker {
  display: inline-flex;
}
.rs-picker-textbox {
  text-align: left;
}

.rs-col {
  text-align: left;
}

#react-doc-viewer {
  border-radius: 10px !important;

  iframe {
    border-radius: 10px !important;
  }
}

.tox-dialog-wrap {
  * {
    font-family: "Montserrat", sans-serif !important;
  }
}

.rs-comment-action-plan {
  .rs-input-dark__control {
    textarea {
      padding: 10px !important;
      top: 10px;
      left: 10px;
    }
  }

  .rs-input-light__control {
    textarea {
      border: 1px solid #fff !important;
      padding: 10px !important;
      top: 10px;
      left: 10px;
      :focus {
        border: 1px solid #fff !important;
      }
    }
  }
}

.wave {
  display: inline-block;
  animation: wave-animation 2s infinite;
  transform-origin: 70% 70%;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
